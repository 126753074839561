<template>
  <div  class="wsRoundedLight" style="border : 1px solid var(--wsBACKGROUND)">
    <v-sheet @click="expand = !expand" :color="wsLIGHTCARD" class="pa-4 pointer">
      <h3  :style="`font-size: ${!SM ? 18 : 13}px`" class="wsDARKER">{{  module.name  }}</h3>
    </v-sheet>

    <v-expand-transition>
      <div v-if="expand" >
        <div
            v-for="(topic,i) in module.topics" :key="i"
            class="px-6 py-4"
            :style="i < module.topics.length -1 ? 'border-bottom: 1px solid var(--wsBACKGROUND);' : null"
        >
          <h4 :style="`font-size: ${!SM ? 16 : 13}px`"  class="wsDARKER">{{ topic.name }}</h4>

          <div v-for="(component,i) in topic.components" :key="i"
               class="d-flex py-2"
          >
            <v-icon :color="wsATTENTION" size="22" class="mr-2"  > {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
            <h4 :style="`font-size: ${!SM ? 16 : 13}px`" class="wsDARKER">{{ component.name }}</h4>
          </div>
        </div>

      </div>
    </v-expand-transition>


  </div>
</template>

<script>
export default {
  name: "marketCourseModule",
  props : {
    module : {
      type: Object,
      default() { return {} }
    },
    expanded : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      expand : false
    }
  },
  methods : {
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open-page-variant'   ; break;
        case 'task'       :  icon = 'mdi-text-box-check-outline'   ; break;
        case 'task_video' :  icon = 'mdi-cast-education'           ; break;
        case 'video'      :  icon = 'mdi-video'                    ; break;
        case 'file'       :  icon = 'mdi-file'                     ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
  },
  mounted() {
    if (this.expanded) {
      this.expand = true
    }
  }
}
</script>

<style scoped>

</style>